import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
	{
	  path: '/about',
	  name: 'About',
	  component: () => import('../views/about.vue')
	},
	{
		path: '/contact',
		name: 'Contact',
		component: () => import('../views/contact.vue')
	},
	{
		path: '/product',
		name: 'Product',
		component: () => import('../views/product.vue')
	},
	{
		path: '/productOne',
		name: 'ProductOne',
		component: () => import('../views/product/product-one.vue')
	},
	{
		path: '/productTwo',
		name: 'ProductTwo',
		component: () => import('../views/product/product-two.vue')
	},
	{
		path: '/productThree',
		name: 'ProductThree',
		component: () => import('../views/product/product-three.vue')
	},
	{
		path: '/productFour',
		name: 'ProductFour',
		component: () => import('../views/product/product-four.vue')
	},
	{
		path: '/productFive',
		name: 'ProductFive',
		component: () => import('../views/product/product-five.vue')
	},
	{
		path: '/productSix',
		name: 'ProductSix',
		component: () => import('../views/product/product-six.vue')
	},
	{
		path: '/productSeven',
		name: 'ProductSeven',
		component: () => import('../views/product/product-seven.vue')
	},
	{
		path: '/productEight',
		name: 'ProductEight',
		component: () => import('../views/product/product-eight.vue')
	},
	{
		path: '/productNine',
		name: 'ProductNine',
		component: () => import('../views/product/product-nine.vue')
	},
	{
		path: '/productTen',
		name: 'ProductTen',
		component: () => import('../views/product/product-ten.vue')
	},
	{
		path: '/productEleven',
		name: 'ProductEleven',
		component: () => import('../views/product/product-eleven.vue')
	},
]

const router = new VueRouter({
  mode:'history',
  routes
})

export default router
