import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 通用样式
import './assets/css/common.css'
// 手机样式
import './assets/css/phone.css'

// element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 移动端图片手指滑动
import VueTouch from 'vue-touch'
Vue.use(VueTouch, {name: 'v-touch'})

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
    Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})

// axios
import axios from 'axios';
axios.defaults.baseURL = window.baseconfig.weatherBaseURL
//全局设置网络超时
axios.defaults.timeout = 10000;
//请求拦截器
axios.interceptors.request.use((config) => {
    return config;
  }, error => {
    return Promise.error(error);
});
//响应拦截器
axios.interceptors.response.use(
	response => {
		if (response.status === 200) {
			return Promise.resolve(response);
		} else {
			return Promise.reject(response);
		}
	},
	error => {
		if (error.status) {
			if (error.status == 401) {
				Message({
					message: '网络错误',
					type: 'error',
					duration: 3 * 1000
				})
			}
		} else {
			Message({
				message: '网络错误',
				type: 'error',
				duration: 3 * 1000
			})
		}
	});
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

Vue.prototype.$ajax = axios

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
